import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Snackbar, Alert, Button } from '@mui/material';
import InputLine from './InputLine.js';
import Entity from './Entity.js';
import y42 from './y42.js';
import i18n from './i18n.js';

export default function Games(props) {
  const pageParams = useParams();
  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
  const [prefixOpen, setPrefixOpen] = useState(false);
  const navigate = useNavigate();
  const langOpts = [];
  const langs = i18n.getLanguages();

  const fields = [      
         { field: 'id', headerName: i18n.t('fID'), width: 70, type: 'number', isReadOnly: true, display: "GU" },
         { field: 'name', headerName: i18n.t('fName'), width: 230, type: 'text', onChange: (() => {}), display: "GCU" },
         { field: 'language', headerName: i18n.t('fLanguage'), width: 80, type: 'list', display: "CU", list: {opts: langOpts}, readOnly: "U" },
         { field: 'simulation', headerName: i18n.t('fSimulation'), width: 130, type: 'ref', isNullable: false, ref: {returnField: 'name', method: 'simulations'}, display: "GCU", readOnly: "U" },
         { field: 'initiator', headerName: i18n.t('fInitiator'), width: 130, type: 'ref', isNullable: false, ref: {returnField: 'name', method: 'users'}, defaultValue: y42.getUser().id, display: "GCU", readOnly: "U" },
         { field: 'accountName', headerName: i18n.t('fAccount'), width: 130, type: 'text', isReadOnly: true, display: "GCU"},
         { field: 'variant', headerName: i18n.t('fVariant'), width: 130, type: 'list', isNullable: true, list: {method : "games/" + pageParams.id + "/variants"}, display: "GU" },
         { field: 'playersNumber', headerName: i18n.t('fNumberOfPlayers'), width: 180, type: 'integer', isNullable: false, display: "GU" },
         { field: 'stepsNumber', headerName: i18n.t('fNumberOfSteps'), width: 160, type: 'integer', isNullable: false, display: "GU" },
         { field: 'minutesForStep', headerName: i18n.t('fMinutesForOneStep'), width: 160, type: 'integer', isNullable: false, display: "U" }, 
         { field: 'moveModeStrict', headerName: i18n.t('fMoveModeStrict'), width: 80, type: 'boolean', isNullable: false, display: "U" },
         { field: 'active', headerName: i18n.t('fIsActive'), width: 80, type: 'boolean', isNullable: false, display: "GU" }
  ];

  for (let i = 0; i < langs.length; i++) {
    langOpts.push({value : langs[i].key, label: langs[i].value});
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({open: false, severity: "info", message: ''});
  };

  const cloneGame = async () => {
    if (pageParams.id === "new") return;

    const game = await y42.get("games/" + pageParams.id);
    const players = await y42.get("game/"  + pageParams.id + "/players");
    let num = 2;
    let err = 1;
    let res = {};
    while (err > 0 && err < 10) {
      const newGame = {
        initiator: {id : game.initiator},
        minutesForStep: game.minutesForStep,
        name: game.name + "-" + num,
        playersNumber: game.playersNumber,
        simulation: {id: game.simulation},
        stepsNumber: game.stepsNumber,
        variant: game.variant
      };
      res = await y42.post("games", null, JSON.stringify(newGame));
      if (res._status === 409) {
        err += 1;
        num += 1;
      } else
        if (res._status === y42.ok)
          err = 0;
        else err = 10;
    }
    if (err > 0) {
      setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
      return;
    }

    const newGame = res;
    for (let p = 0; p < newGame.playersNumber; p++) {
      const pl = players.content[p];
      const users = [];
      for (let u = 0; u < pl.users.length; u++) {
        users.push({id: pl.users[u]})
      }
      const player = {
        game: {id: newGame.id},
        name: pl.name,
        profile: pl.profile,
        isRobot: pl.isRobot,
        script: pl.script,
        users: users
      };
      res = await y42.post("players", null, JSON.stringify(player));
      if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)})
        return;
      }
    }
    let situations = {};
    for (let i = 0; i < Object.keys(game.situations).length; i++) {
      situations[i] = JSON.parse(game.situations[Object.keys(game.situations)[i]]);
    }
    res = await y42.post("games/" + newGame.id + "/sendSituation", "", JSON.stringify(situations));
    if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
        return;
    }
    res = await y42.post("games/" + newGame.id + "/sendSituationDescription", "", JSON.stringify(game.situationDescriptions));
    if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
    } else {
        setAlert({open: true, severity: "info", message: i18n.t("mGameCloned"), link: {title: newGame.name, onClick: () => navigate("../games/" + newGame.id + "/run")}})
    }
  }

  const openGenPlayers = () => {
    if (pageParams.id === "new") return;
    setPrefixOpen(true);
  }

  const genPlayers = async (prefix) => {
    if (pageParams.id === "new") return;

    if (prefix.trim() === "") {
      setAlert({open: true, severity: "error", message: i18n.t("errEmptyPrefix")})
      return;
    }

    let res = await y42.post("games/" + pageParams.id + "/generatePlayers", null, prefix);
    if (res._status !== y42.ok) {
      setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)})
      return;
    }
  }

  const buttons = [
    { name: i18n.t('bGeneratePlayers'), onClick : openGenPlayers, display : "U"},
    { name: i18n.t('bCloneGame'), onClick : cloneGame, display : "U"},
    { name: i18n.t('bPlayers'), path : pageParams.id + "/players", display : "U"},
    { name: i18n.t('bRunGame'), path : pageParams.id + "/run", display : "U"}
  ];

  return (
    <>
      <Snackbar open={alert.open} 
          autoHideDuration={6000} 
          onClose={handleClose} 
          anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                 }}
          sx={props.sx}>
          <Alert onClose={handleClose} severity={alert.severity}>
            {alert.message}
            { alert.link ? <Button onClick={alert.link.onClick}>{alert.link.title}</Button> : ""}
          </Alert>
      </Snackbar>
      <InputLine 
        open={prefixOpen}
        title={i18n.t("lInputPrefix")}
        label={i18n.t("lPrefix")}
        onSave={(prefix) => { genPlayers(prefix);} }
        onClose={() => { setPrefixOpen(false); }}
      />
      <Entity 
        title={i18n.t("tGames")}
        props={props} 
        buttons={buttons}
        fields={fields}
        method="games"
        />
    </>    
  );
}