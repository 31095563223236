import React, { useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import { Drawer as MuiDrawer, AppBar, Toolbar, List, Typography, IconButton, TextField, Autocomplete, Divider, ListItem, ListItemIcon } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import MenuItems from './MenuItems.js';
import ChangePassword from './ChangePassword.js';
import i18n from './i18n.js';
import y42 from './y42.js';
import theme from './../theme.js';
import { Icon, Box } from '@mui/material';
import { useSelector } from 'react-redux';

const windowWidth = window.innerWidth;
let isSm = (windowWidth <= theme.breakpoints.values.sm);
const drawerWidth = isSm?windowWidth:240;
const drawerZero = isSm?0:theme.spacing(8);

const openedMixin = (theme) => ({
  width: drawerWidth
});

const closedMixin = (theme) => ({
  overflowX: 'hidden',
  width: drawerZero
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(

  ({ theme, open }) => ({
    display: 'flex',
    flexShrink: 0,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const MenuFooter = styled('div')(({ theme }) => ({  
  [theme.breakpoints.down('sm')]: {
  },
  [theme.breakpoints.up('sm')]: {
    position: "fixed",
    bottom: 0,
  },
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: drawerWidth,
  alignItems: 'center'
}));

const MenuButton = styled('div')(({ theme }) => ({  
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  alignItems: 'center',
  cursor: 'pointer',
  display: 'inline-block'
}));


export default function BasicPage(props) {
  const navigate = useNavigate();
  const title = useSelector(state => state.title);

  const langs = i18n.getLanguages();
  const [lang, setLang] = useState(langs.find(item => item.key === localStorage.getItem("lang")));
  
  let globalMainDrawerState = localStorage.getItem("mainDrawer");
  globalMainDrawerState =  (globalMainDrawerState === null || globalMainDrawerState === "true");
  const [open, setMenuOpen] = useState(globalMainDrawerState);
  const [passOpen, setPassOpen] = useState(false);

  useEffect(() => {
    let user = y42.getUser();
    if (!user || !user.role) {
      sessionStorage.setItem('returnURL', window.location.pathname);
      navigate("/");
      return;
    };
  });


  const handleDrawerOpen = () => {
    if (open) {
      localStorage.setItem("mainDrawer", false)
      setMenuOpen(false);
    } else {
      localStorage.setItem("mainDrawer", true)
      setMenuOpen(true);
    }
  };

  const closeDrawer = () => {
    //console.log(windowWidth + "<=" + theme.breakpoints.values.sm);
    if (isSm) handleDrawerOpen();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuChangePassword = () => {
    setAnchorEl(null);
    setPassOpen(true);
  };
  const handleMenuLogout = () => {
    setAnchorEl(null);
    y42.logout();
    navigate("/");
  };

  let homeAddress = "/";
  let user = y42.getUser();
  if (!user || !user.role) {
    return;
  };

  if (user.role.name === "ROLE_ADMIN")
    homeAddress = "/admin";
  if (user.role.name === "ROLE_ACCMANAGER")
    homeAddress = "/manage";
  if (user.role.name === "ROLE_USER") 
    homeAddress = "/home";

  let ttl = title.value;
  if (ttl === "")
    ttl = props.title;
  if (ttl === "")
    ttl = props.recordTitles;

  const userMenu = 
         (<>  
            <MenuButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuButtonClick}>         
              <Typography variant="body1" sx={{mb: -1, ml: -1}}>
                {user.fullName}
              </Typography>
              <Typography variant="caption" sx={{ml: -1}}>
                {user.name} • {user.account.name}
                {user.role.name === "ROLE_ADMIN" ? " • " + i18n.t("lAdmin") : user.role.name === "ROLE_ACCMANAGER" ? " • " + i18n.t("lAccManager") : ""}
              </Typography>
            </MenuButton>
            <Menu
              id="profileMenu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => {setAnchorEl(null);}}
              sx={{
                  zIndex: 2000,
                  justifyContent: 'flex-end'
              }}
            >
              <MenuItem onClick={handleMenuChangePassword}>{i18n.t("mChangePassword")}</MenuItem>
              <MenuItem onClick={handleMenuLogout}>{i18n.t("mLogout")}</MenuItem>
            </Menu>
          </>);

  const drawer = (      
    <React.Fragment key="drawer">
          <DrawerHeader />
          <Box>
            <List>{MenuItems({menuItems: props.menuItems, callback: closeDrawer})}</List>
          </Box>
          <Box sx={{[theme.breakpoints.up('sm')]: {display: 'none'}}}>
              <Divider/>
              <ListItem button key={"userMenu"}>
                <ListItemIcon>
                    <Icon>{"person"}</Icon>
                </ListItemIcon>
                { userMenu }
              </ListItem>
              <Divider/>
          </Box>
          <MenuFooter>
            <Autocomplete
                id="languageSelect" 
                key="languageSelect"
                name="languageSelect"
                fullWidth
                label={i18n.t("fSelectLanguage")} 
                disableClearable
                options={langs}
                value={lang}
                onChange={(ev, value) => {
                              i18n.config(value.key);
                              setLang(value);
                              window.location.reload();
                            }}
                getOptionLabel={
                              (option) => {
                                return (option && option.value)?option.value:""
                                }
                            }
                isOptionEqualToValue={
                  (option, value) => option.key === value.key
                }
                renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    margin="dense"
                                    variant="outlined"/>
                              )}
                sx = {{display: open?"block":"none"}}
              />
            </MenuFooter>
    </React.Fragment>);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{zIndex: 1300}}>
        <Toolbar sx={{display: 'flex', flexDirection: 'row'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5      
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={()=>{navigate(homeAddress)}}>
            <Icon sx={{color: "white"}}>home</Icon>
          </IconButton>
          <Box sx={{flexGrow: 1}}>
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              {ttl}
            </Typography>
          </Box>
          <Box sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
            <Icon sx={{mr: 1}}>{"person"}</Icon>
            { userMenu }
           </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open} sx={{zIndex: 1100}}>
          {drawer}
      </Drawer>
      <Box sx={{    padding: theme.spacing(2),
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    overflowX: 'hidden'
              }}>
        <Toolbar/>
        {props.routesComponents}
      </Box>
      <ChangePassword open={passOpen} onClose={() => {setPassOpen(false)}}/>
    </Box>
  );
}