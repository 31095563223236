import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from "react-router-dom";
import { Box } from '@mui/material';
import gfm from 'remark-gfm';
import './../assets/css/md.css';
import AlertMessage from './AlertMessage.js';
import PrintCharts from './PrintCharts.js';
import i18n from './i18n.js';
import y42 from './y42.js';

export default function PrintReport(props) {
   const pageParams = useParams();
   const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
   const [state, setState] = useState({isLoaded : false, isLoading : false});
   const [report, setReport] = useState("");
   const [playerId, setPlayerId] = useState(-1);
   const [step, setStep] = useState(-1);
   var gs = {};
   let chartComponents = [];
   let year = new Date().getFullYear();


    useEffect(() => {
       async function fetchReport() {
          setState({isLoaded : true, isLoading : false});
          let gameId = pageParams.gameId;
          let plId = pageParams.playerId;
          let st = pageParams.step;
          const res3 = await y42.get("games/" + gameId + "/playerReport/" + plId + "/" + st);
          if (res3._status !== y42.ok)
            setAlert({open: true, severity: "error", message: y42.getMessage(res3._status, res3.message)});
          else {
            setReport(res3.report);
            setState({isLoaded : true, isLoading : false});
          }
        }

        setPlayerId(pageParams.playerId);
        setStep(pageParams.step);
        if (state.isLoaded || state.isLoading)
              return;
        fetchReport();
    }, [pageParams.playerId, pageParams.step, state.isLoaded, state.isLoading, pageParams.gameId]);

    if (state.isLoaded && gs.isGameStarted && gs.game.currentStep >= 0) {
      chartComponents = (<PrintCharts gs={gs} playerId={playerId} step={step}/>)
    }

    return (
      <>
      <main>
        <Box sx={{pl: 2}}>
        <AlertMessage open={alert.open} severity={alert.severity} message={alert.message}/>

        <div className="markdown-body">
            <h5>{i18n.t("tReport") + " " + y42.getSiteName() + " Simulation Platform, ©2020-" + year + " " + y42.getSiteUrl()}</h5>
            <hr/>
            <h1>{i18n.t("tReport")}</h1>
            <ReactMarkdown remarkPlugins={[gfm]}>
              {report}
            </ReactMarkdown>
            {chartComponents}
        </div>
        </Box>
      </main>
      </>
    )
}