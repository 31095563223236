import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField as MuiTextField, Switch, FormGroup, FormControlLabel, Paper as MuiPaper, Box, Grid, Alert, Snackbar } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Autocomplete, Typography, IconButton } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';
import dateFormat from 'dateformat';
import CodeEditor from './CodeEditor.js';
import Loading from './Loading.js';
import Breadcrumbs from './Breadcrumbs.js';
import i18n from './i18n.js';
import y42 from './y42.js';

const Paper = styled(MuiPaper)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      width: '100ch',
    },
    [theme.breakpoints.down('sm')]: {

    },
    flexGrow: 1,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    minHeight: '75vh'
}));

const PaperGrid = styled(MuiPaper)(({ theme }) => ({
    flexGrow: 1,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5)
}));


const TextField = styled(MuiTextField)(({ theme }) => ({
    margin: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
}));

export default function Entity(props) {
  const navigate = useNavigate();
  const pageParams = useParams();

  const [state, setState] = useState({
      id: pageParams.id?pageParams.id:null,
      data: null
  })

  const [page, setPage] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [queryOptions, setQueryOptions] = useState({});
  const [filterString, setFilterString] = useState("");

  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
  const [dialog, setDialog] = React.useState(false);

  const [refs, setRefs] = useState({});
  const [loadState, setLoadState] = useState({isLoaded : false, isLoading : false});
  const [updateOnly, setUpdateOnly] = useState(false);
  const fields = props.fields;
  const buttons = props.buttons;
  const { id, data } = state;

  useEffect(() => {
    async function fetch() {
      setLoadState({isLoaded : false, isLoading : true});

      let _fields = props.fields;
      let newRefs = {};

      for (let i = 0; i < _fields.length; i++) {
        let field = fields[i];
        if (field.type.toLowerCase() === "ref" && field.ref.method && !newRefs[field.field]) {
          const res = await y42.get(field.ref.method, "?size=10000");
          if (res._status !== y42.ok) {
            setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message) + " " + i18n.t("lWhileGetting") + " " + field.ref.method});
          } else {
            let opts = res.content.map((item) => {
                return { value: item.id, label: item[field.ref.returnField]}
            }); 
            newRefs[field.field] = opts;
          }
        } else if (field.type.toLowerCase() === "list"  && !newRefs[field.field]) {
          if (field.list.method) {          
            const res = await y42.get(field.list.method);
            if (res._status !== y42.ok) {
              setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message) + " " + i18n.t("lWhileGetting") + " " + field.list.method});
            } else {
              let opts = res.map((item) => {
                  return { value: item, label: item}
              }); 
              newRefs[field.field] = opts;
            }
          } else if (field.list.opts) {
              newRefs[field.field] = field.list.opts;
          }
        } 
      }

      setRefs(newRefs);

      if (pageParams.id === "new") {
        let dta = {};
        if (props.filteredByField && props.filteredByField != null && pageParams.fid && pageParams.fid != null)
          dta[props.filteredByField] = {id: pageParams.fid * 1};
        for (let i = 0; i < _fields.length; i++) {
          let field = fields[i];
          if (field.defaultValue !== undefined && field.defaultValue !== null)
            if (field.type.toLowerCase() === "ref")
              if (field.isMultiple === true)
                dta[field.field] = [{id: field.defaultValue}];
              else
                dta[field.field] = {id: field.defaultValue};
            else 
              dta[field.field] = field.defaultValue;
        }
        setState({
          id: "new",
          data: dta
        });
        setLoadState({isLoaded : true, isLoading : false});
      } else {
        if (pageParams.id && pageParams.id !== null) {
          const res = await y42.get(props.method, pageParams.id);
          if (res._status !== y42.ok) {
             setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
          } else
          {
            setState({
              id: pageParams.id,
              data: res
            });
            setLoadState({isLoaded : true, isLoading : false});
          }
       } else {
          let mtd = "";
          if (props.gridMethod && props.gridMethod !== "")
            mtd = props.gridMethod;
          else
            mtd = props.method;
          let p = 0;
          if (page.page !== undefined)
            p = page.page;
          let sortBy = "";
          
          if (queryOptions.sortModel !== undefined && queryOptions.sortModel.length > 0)
            sortBy = "&sort=" + queryOptions.sortModel[0].field + ","+queryOptions.sortModel[0].sort;

          let filter = "&q=";
          if (filterString !== "" && filterString !== "undefined") {
            filter += filterString;
          }            

          const res = await y42.get(mtd, "?page=" + p + "&size=" + pageSize + sortBy + filter);
          if (res._status !== y42.ok) {
            setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
          } else
          {
            setState({
              id: null,
              data: res.content,
              rowCount: res.totalElements
            });
            setLoadState({isLoaded : true, isLoading : false});
          }
       }
      }
    }

    if ((loadState.isLoading || loadState.isLoaded))
      return;

    fetch();

  }, [pageParams.id, pageParams.fid, props.filteredByField, props.gridMethod, props.method, 
      props.fields, loadState.isLoading, loadState.isLoaded, fields, id, queryOptions, filterString, page, pageSize]);


  useEffect(() => {
    const fetch = async function () {
      if (loadState.isLoading) return;

      let mtd = "";
      if (props.gridMethod && props.gridMethod !== "")
        mtd = props.gridMethod;
      else
        mtd = props.method;
      let p = 0;
      if (page.page !== undefined)
        p = page.page;
      let sortBy = "";

      if (queryOptions.sortModel !== undefined && queryOptions.sortModel.length > 0)
        sortBy = "&sort=" + queryOptions.sortModel[0].field + ","+queryOptions.sortModel[0].sort;

      let filter = "&q=";
      if (filterString !== "" && filterString !== "undefined") {
        filter += filterString;
      }            

      const res = await y42.get(mtd, "?page=" + p + "&size=" + pageSize + sortBy + filter);
      if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
      } else
      {
        setState({
          id: null,
          data: res.content,
          rowCount: res.totalElements
        });
        setLoadState({isLoaded : true, isLoading : false});
      }
    }

    if (updateOnly) {
      fetch();
      setUpdateOnly(false);
    }

  }, [pageParams.id, pageParams.fid, props.filteredByField, props.gridMethod, props.method, 
      props.fields, fields, id, queryOptions, filterString, page, pageSize, updateOnly, loadState.isLoading]);

  const fetchFilter = () => {
    setUpdateOnly(true);
  }

  const save = () => {
      delete state.data._status;

      let okeys = Object.keys(state.data);
      for (let i = 0; i < okeys.length; i++) {
        let field = props.fields.find(f => f.field === okeys[i]);
        if (field && field.type.toLowerCase() === "ref")
          if (!field.isMultiple) {
            if (!state.data[okeys[i]].hasOwnProperty("id"))
              state.data[okeys[i]] = {id: state.data[okeys[i]]};
          } else
            state.data[okeys[i]] = arrayToIds(state.data[okeys[i]]);
        if (field && field.type.toLowerCase() === "list")
          if (state.data[okeys[i]] !== null)
            if (!state.data[okeys[i]].hasOwnProperty("value"));
               // state.data[okeys[i]] = state.data[okeys[i]];
            else
              state.data[okeys[i]] = state.data[okeys[i]].value;
      }

      if (state.id === "new")
        y42.post(props.method, null, JSON.stringify(state.data))
         .then((res) => {
            if (res._status !== y42.ok) {
              setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
            } else
            {
              setAlert({open: true, severity: "info", message: i18n.t("lSavedSuccessfully")});
              //navigate(props.props.location.pathname.substring(0, props.props.location.pathname.length - 3) + res.id, {replace : true});
              navigate("./../" + res.id, {replace: true});
              setState({
                id: res.id,
                data: state.data
              });
              setLoadState({isLoaded : false, isLoading : false});
            }
          });       
      else
        y42.put(props.method, state.id, JSON.stringify(state.data))
          .then((res) => {
            if (res._status !== y42.ok) {
              setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
            } else
            {
              setAlert({open: true, severity: "info", message: i18n.t("lSavedSuccessfully")});
              setState({
                id: state.id,
                data: state.data
              });
              setLoadState({isLoaded : false, isLoading : false});
            }
          });
  };

  const del = () => {
    setDialog(false);
    if (state.id !== "new") {
      y42.del(props.method, state.id)
        .then((res) => {
            if (res._status !== y42.ok) {
              setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
            } else {
              setAlert({open: true, severity: "info", message: i18n.t("lDeletedSuccessfully")});
              //let len = (state.id + "").length + 1;
              //navigate(props.props.location.pathname.substring(0, props.props.location.pathname.length - len), {replace: true});
              clearLoadState();
              navigate("./..", {replace: true});
            }          
        })
    }
  }

  const idsToArray = (value) => {
    if (!value || !Array.isArray(value))
      return (value.id)?value.id:value;

    let a = [];
    for (let  i = 0; i < value.length; i++)
      if (value[i].id)
        a.push(value[i].id)
      else
        a.push(value[i]);
    return a;
  };

  const arrayToIds = (value) => {
    if (!value || !Array.isArray(value))
      return {id: value.value};

    let a = [];
    for (let i = 0; i < value.length; i++)
      if (value[i].hasOwnProperty("id"))
        a.push(value[i]);
      else
        if (value[i].hasOwnProperty("value"))
          a.push({id: value[i].value});
        else
          a.push({id: value[i]});
    return a;
  };

  const handleChange = (ev) => {
      let newData = state.data;
      let el = ev.target.name;
      newData[el] = ev.target.value;

      setState({
          id: state.id,
          data: newData
      });
  };

  const handleChangeSelect = (name, value) => {
      let newData = state.data;
      //let el = ev.target.id.substring(4, ev.target.id.indexOf("#", 5));
      if (value === null)
        newData[name] = {};
      else
        newData[name] = {id: value.value};

      setState({
          id: state.id,
          data: newData
      });
  }

  const handleChangeSelectMultiple = (name, value) => {
      let newData = state.data;

      //let el = ev.target.id.substring(4, ev.target.id.indexOf("#", 5));
      //let el = ev.target.name;
      
      if (value === null)
        newData[name] = [];
      else {
        newData[name] = arrayToIds(value);
      }

      setState({
          id: state.id,
          data: newData
      });
  };

    const handleChangeList = (name, value) => {
      let newData = state.data;
      if (value === null)
        newData[name] = "";
      else
        newData[name] = value.value;
      setState({
          id: state.id,
          data: newData
      });
  }

  const handleChangeCodeEditor = (fieldName, newValue) => {
      let newData = state.data;
      newData[fieldName] = newValue;
      setState({
          id: state.id,
          data: newData
      });      
  }

  const handleChangeCheckbox = (ev) => {
      let newData = state.data;
      let el = ev.target.name;
      newData[el] = ev.target.checked;

      setState({
          id: state.id,
          data: newData
      });
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
  };

  const clearLoadState = () => {
    setLoadState({isLoaded: false, isLoading: false}); 
  }

  const handleToList = () => {
    //navigate(props.props.location.pathname.substring(0, props.props.location.pathname.length - len), {replace: true});
    clearLoadState();
    navigate("./..");
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({open: false, severity: "info", message: ''});
  };

  const handleBlur = async (e, field) => {
      if (field.onChange && field.onChange !== null) {
        field.onChange(data[field.field], e.target.value, refs);
      }
  }


  let renderedButtons = "";
  if (buttons)
    renderedButtons = buttons.map((button, idx) => {
        if (state.id && state.id === "new" && button.display && !button.display.toUpperCase().includes("C"))
          return "";
        if (state.id && state.id !== "new" && button.display && !button.display.toUpperCase().includes("U"))
          return "";

        if (button.path)
          return (<Button key={button.name + "_btn" + idx} sx={{mr: 1, ml: 1}} color="secondary" href={button.path}>{button.name}</Button>);
        else
          return (<Button key={button.name + "_btn" + idx} sx={{mr: 1, ml: 1}} color="secondary" onClick={button.onClick}>{button.name}</Button>);
      });

  if (!loadState.isLoaded || refs === null)
    return (<Loading />);

  let recordTitles = [];

  if (id !== null) {
    let btn = null;
    if (id !== "new")
      btn = <Button sx={{mr: 1, ml: 1}} color="secondary" onClick={() => {clearLoadState(); navigate("./../new"); }}>{i18n.t("bNew")}</Button>

    document.title = y42.getSiteName() + " " + (data["name"]?data["name"]:i18n.t("lNewRecord"));
    if (props.filteredByField && props.filteredByField != null 
      && pageParams.fid && pageParams.fid != null) {
       let el = refs[props.filteredByField].find(item => item.value === pageParams.fid * 1);
       recordTitles.push(el.label);
    }
  
    recordTitles.push(data["name"]?data["name"]:"");
    return (
      <>
      <Breadcrumbs recordTitles={recordTitles} callback={clearLoadState}/>
      <div>
        <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
          <Alert onClose={handleClose} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>


        <Dialog
          open={dialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{i18n.t("tDeleteRecord")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {i18n.t("lAreYouSureToDelete")}
              </DialogContentText>
             </DialogContent>
             <DialogActions>
                <Button onClick={handleDialogClose} sx={{mr: 1, ml: 1}} color="primary">
                  {i18n.t("bCancel")}
                </Button>
                <Button onClick={del} sx={{mr: 1, ml: 1}} color="primary" autoFocus>
                  {i18n.t("bDeleteRecord")}
                </Button>
              </DialogActions>
          </Dialog>
      </div>
      <Box sx={{paddingLeft: 0, paddingTop: 2}}>
        <Button color="primary" sx={{mr: 1, ml: 1}} onClick={save} >{i18n.t("bSave")}</Button>
        <Button color="primary" sx={{mr: 1, ml: 1}} onClick={handleDialogOpen} >{i18n.t("bDelete")}</Button>
        {
          renderedButtons
        }
        { btn }
        <Button color="secondary" sx={{mr: 1, ml: 1}} onClick={handleToList}>{i18n.t("bToList")}</Button>
      </Box>
      <Paper>
        <form noValidate autoComplete="off">
        {fields.map((field, idx) => {
            if (state.id && state.id === "new" && field.display && !field.display.toUpperCase().includes("C"))
              return "";
            if (state.id && state.id !== "new" && field.display && !field.display.toUpperCase().includes("U"))
              return "";

            if (state.id && state.id === "new" && field.readOnly && field.readOnly.toUpperCase().includes("C"))
              field.isReadOnly = true;
            if (state.id && state.id !== "new" && field.readOnly && field.readOnly.toUpperCase().includes("U"))
              field.isReadOnly = true;

            let m = "";
            
            if (field.type.toLowerCase() === 'text' || field.type.toLowerCase() === 'string')
              m = (<div key={idx}>
                    <TextField
                      id={"inp_" + field.field}
                      key={"inp_" + field.field}
                      name={field.field} 
                      label={field.headerName}  
                      InputProps={{
                        readOnly: field.isReadOnly,
                      }}
                      fullWidth
                      disabled={field.isReadOnly}
                      margin="dense"
                      variant="outlined"
                      defaultValue={data[field.field]}
                      onBlur={(e) => handleBlur(e, field)}
                      onChange={handleChange}
                      />
                    </div>);

            if (field.type.toLowerCase() === 'code')
              m = (<div key={idx}>
                      <CodeEditor 
                        language="javascript"
                        id={"inp_" + field.field}
                        key={"inp_" + field.field}
                        name={field.field}
                        label={field.headerName}  
                        value={data[field.field]}
                        saveButtonLabel={i18n.t("bCodeSave")}
                        onChange={handleChangeCodeEditor}
                        saveCallback={save}
                      />
                    </div>);

              if (field.type.toLowerCase() === 'integer' || field.type.toLowerCase() === 'number')
              m = (<div key={idx}>
                    <TextField
                      id={"inp_" + field.field}
                      key={"inp_" + field.field}
                      name={field.field} 
                      variant="outlined"
                      inputProps={{
                        "pattern": "[0-9]*"
                      }}
                      label={field.headerName}  
                      InputProps={{
                        readOnly: field.isReadOnly,
                      }}
                      fullWidth
                      disabled={field.isReadOnly}
                      margin="dense"
                      defaultValue={data[field.field]}
                      onBlur={(e) => handleBlur(e, field)}
                      onChange={handleChange}
                      />
                    </div>);

              if (field.type.toLowerCase() === 'datetime') {
                let v = data[field.field];
                if (v == null) v = "";
                v = v.toString().substring(0,16);
                m = (<div key={idx}>
                      <TextField
                        id={"inp_" + field.field}
                        key={"inp_" + field.field}
                        name={field.field} 
                        type="datetime-local"
                        label={field.headerName}  
                        variant="outlined"
                        InputProps={{
                          readOnly: field.isReadOnly,
                        }}
                        fullWidth
                        disabled={field.isReadOnly}
                        margin="dense"
                        defaultValue={v}
                        onBlur={(e) => handleBlur(e, field)}
                        onChange={handleChange}
                        />
                      </div>);
              }

              if (field.type.toLowerCase() === 'boolean')
                m = (<div key={idx}>
                    <FormGroup>
                      <FormControlLabel 
                        label={field.headerName}
                        control={
                            <Switch
                              id={"inp_" + field.field}
                              key={"inp_" + field.field}
                              name={field.field} 
                              variant="outlined"
                              inputprops={{
                                readOnly: field.isReadOnly,
                              }}
                              margin="dense"
                              checked={data[field.field]}
                              onBlur={(e) => handleBlur(e, field)}
                              onChange={handleChangeCheckbox}
                              />
                        }
                      />
                    </FormGroup>
                    </div>);

              if (field.type.toLowerCase() === 'list' && refs[field.field]) {
                let fOnChange = handleChangeList;
                let defLabel = refs[field.field].find(item => item.value === data[field.field]);
                if (!defLabel || defLabel === null || defLabel === "")
                  defLabel = data[field.field];
                else
                  defLabel = defLabel.label;
                let def = {value: data[field.field], label: defLabel};
                m = (<div key={idx}>
                      <Autocomplete
                        id={"inp_" + field.field + "#"} 
                        key={"inp_" + field.field}
                        name={field.field} 
                        disableClearable={!field.isNullable}
                        disabled={field.isReadOnly}
                        options={refs[field.field]}
                        value={def}
                        onBlur={(e) => handleBlur(e, field)}
                        onChange={(ev, value) => {
                          fOnChange(field.field, value);
                        }}
                        isOptionEqualToValue={
                           (option, value) => 
                            { if ((value === null || value.value === null) && (option.value === null || option.value === ""))
                                return true;
                              if (value.value === undefined) return true;
                              if (!option.value) return option === value; 
                                else return option.value === value.value; }
                        }
                        getOptionLabel={
                          (option) => {
                            return (option&&option.label)?option.label:""
                            }
                        }
                        renderInput={(params) => (
                            <TextField 
                                label={field.headerName}
                                {...params} 
                                fullWidth
                                variant="outlined" 
                                margin="dense"/>
                          )}
                      />

                    </div>);
              }

              if (field.type.toLowerCase() === 'ref' && refs[field.field]) {
                let fOnChange = handleChangeSelect;
                if (field.isMultiple)
                  fOnChange = handleChangeSelectMultiple;

                let def;
                if (data[field.field]) {
                  let v = idsToArray(data[field.field]);
                  if (Array.isArray(v)) {
                    def = [];
                    v.map(el => {
                      def.push(refs[field.field].find(item => item.value === el));
                      return null;
                    });
                  }
                  else {
                    def = refs[field.field].find(item => item.value === v);
                  }
                }
                else {
                  if (refs[field.field].length === 1 && !field.isNullable) {
                    def = refs[field.field][0];
                    fOnChange(field.field, refs[field.field][0]);
                  }
                  else
                    if (field.isMultiple)
                      def = [];
                    else {
                      def = null;
                    }
                }


                m = (<div key={idx}>
                      <Autocomplete
                        id={"inp_" + field.field + "#"} 
                        key={"inp_" + field.field}
                        name={field.field} 
                        multiple={field.isMultiple}
                        disableClearable={!field.isNullable}
                        disabled={field.isReadOnly}
                        options={refs[field.field]}
                        value={def}
                        onBlur={(e) => handleBlur(e, field)}
                        onChange={(ev, value) => {
                          fOnChange(field.field, value);
                        }}
                        getOptionLabel={
                          (option) => {
                            return (option&&option.label)?option.label:""
                            }
                        }
                        isOptionEqualToValue={
                           (option, value) => { 
                              if ((value === null || value.value === null) && (option.value === null || option.value === ""))
                                return true;
                              if (value.value === undefined) return true;
                              if (!option.value) return option === value; 
                                else return option.value === value.value; }
                        }
                        renderInput={(params) => (
                            <TextField 
                                label={field.headerName}
                                {...params} 
                                fullWidth
                                variant="outlined" 
                                margin="dense"/>
                          )}
                      />

                    </div>);
              }
            return m;
          })
        }
          </form>
        </Paper>
      </>
    );
  }
  else {
    let topLevelFieldValue = "";
    let gridFields = [];
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].display && !fields[i].display.toUpperCase().includes("G"))
        continue;
      gridFields.push(fields[i]);
    }

    gridFields.map((field) => {
      if (field.type.toLowerCase() === "ref") {
        if (!field.isMultiple) {
          field.valueGetter = ((params) => {
            if (!refs[field.field])
              return "";
            let refEntityId = params.value;
            if (refEntityId != null && refEntityId.id)
              refEntityId = refEntityId.id;
            if (!refEntityId || refEntityId == null)
              return "";
            let el = refs[field.field].find(item => item.value === refEntityId);
            if (el && el.label !== null)
              return el.label;
            else
              return "";
          });
          if (props.filteredByField && props.filteredByField != null 
              && pageParams.fid && pageParams.fid != null
              && field.field === props.filteredByField) 
              topLevelFieldValue = field.valueGetter({value : pageParams.fid * 1});
      }  else
          field.valueGetter = ((params) => {
            if (!refs[field.field])
              return "";
            let refEntityIds = params.value;
            let s = "";
            for (let i = 0; i <= refEntityIds.length; i++) {
              let refEntityId = refEntityIds[i];
              if (refEntityId != null && refEntityId.id)
                refEntityId = refEntityId.id;
              if (!refEntityId || refEntityId == null)
                continue;
              let el = refs[field.field].find(item => item.value === refEntityId);
              if (el && el.label !== null)
                s = s + ", " + el.label;
            }
            return s.substring(2);
         }); 

      if (field.type.toLowerCase() === "datetime")
        field.valueGetter = ((params) => {
            if (params.value === null || params.value === "")
              return "";
            return dateFormat(params.value, "dd.mm.yyyy HH:MM")
        });
      }
      return null;
    });

    if (props.filteredByField && props.filteredByField != null && pageParams.fid && pageParams.fid != null)
      recordTitles.push(topLevelFieldValue);

    let btn = "";
    if (!props.excludeStandardButtons) {
       btn = (
          <Button sx={{mr: 1, ml: 1}} color="primary" onClick={() => { clearLoadState(); navigate("./new"); }}>{i18n.t("bNew")}</Button>
        );
    }

    if (props.openCardIfSingle && data.length === 1) {
      navigate("./" + data[0].id);
      return null;
    } else {
      for (let i = 0; i < gridFields.length; i++) {
        let t = gridFields[i].type.toLowerCase();
        if (t === "integer")
          gridFields[i].type = "number";
        if (t === "text" || t === "ref" || t === "list")
          gridFields[i].type = "string";
        if (t === "datetime") {
          gridFields[i].type = "dateTime";
          gridFields[i].valueFormatter = ((params) => {
            if (params.value === null || params.value === "")
              return "";
            return dateFormat(params.value, "dd.mm.yyyy HH:MM")
          });
        }
        gridFields[i].sortable = true; 
      }
      document.title = y42.getSiteName() + " " + props.title; 
      return (
        <>
           <Breadcrumbs recordTitles={recordTitles}  callback={clearLoadState}/>
           <Box sx={{paddingLeft: 0, paddingTop: 2}}>
           { btn }
           </Box>
           <PaperGrid>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Typography sx={{mb: 2}}>{i18n.t("lFilter")}</Typography>
                <TextField
                  sx={{width: "40ch", mb: 2, ml: 2}}
                  id={"filter"}
                  key={"filter"}
                  value={filterString}
                  variant="outlined"
                  size="small"
                  onChange={(ev) => { setFilterString(ev.target.value); }}
                  onKeyPress={(ev) => { if (ev.key === 'Enter') fetchFilter(); }}
                  onKeyDown={(ev) => {
                      if (ev.keyCode === 27 && filterString !== "") { setFilterString("");  fetchFilter(); }
                  }}
                  InputProps={{
                    autoFocus : true,
                    endAdornment: (<IconButton 
                                        onClick={() => { setFilterString("");  fetchFilter();  }} 
                                        sx={{visibility: filterString === "" ? "hidden" : "visible", mr: -1}} size="small">
                                      <ClearIcon/>
                                   </IconButton>)
                  }}
                />
                <Button
                  sx={{ml: 2, mb: 2}}
                  key="fiterButton"
                  variant="contained"
                  color="secondary"
                  onClick={ () => {clearLoadState();} }>
                  {i18n.t("bFilter")}
                </Button>
              </Grid>
              <DataGrid 
                autoHeight
                key="1"
                rows={data} 
                columns={gridFields} 
                pageSize={pageSize} 
                disableMultipleSelection={true}
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                rowCount={state.rowCount}
                page={page.page}
                disableColumnFilter
                disableColumnSelector
                sortModel={queryOptions.sortModel}
                onSortModelChange={ (sortModel) => { 
                      setQueryOptions({ sortModel: [...sortModel] });
                      fetchFilter(); //clearLoadState();
                }}
                onPageChange={(newPage) => { 
                    setPage({page: newPage}); 
                    fetchFilter(); //clearLoadState();
                }}
                onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                    fetchFilter(); //clearLoadState();
                }}
                onRowClick={(params) => {
                  clearLoadState();
                  navigate("./" + params.row.id.toString())}
                }
            />
          </PaperGrid>
        </>  
      );
    }
  }
}
