import React from 'react';
import Entity from './Entity.js';
import i18n from './i18n.js';

export default function Accounts(props) {
  const fields = [
     { field: 'id', headerName: i18n.t('fID'), width: 70, type: 'integer', isReadOnly: true, display: "GU" },
     { field: 'name', headerName: i18n.t('fName'), width: 230, type: 'text' },  
     { field: 'code', headerName: i18n.t('fInvitationCode'), width: 230, type: 'text', isReadOnly: true },
     { field: 'terminationDate', headerName: i18n.t('fTerminationDate'), width: 230, type: 'datetime'},
     { field: 'maxPlayers', headerName: i18n.t('fMaxPlayers'), width: 260, type: 'integer'}
  ];

  return (
    <Entity 
      title={i18n.t("tAccounts")}
      props={props} 
      fields={fields}
      method="accounts"/>
  );
}
