import React, { useState } from "react";
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import i18n from './i18n.js';

const InputLine = props => {
  const [line, setLine] = useState("");

  const handleChange = (event) => {
  	setLine(event.target.value);
  }

  const save = () => {
  	props.onSave(line);
 		handleClose();
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
  	<>
      <Dialog open={props.open} onClose={handleClose} width="100ch" onKeyPress={(ev) => { if (ev.key === 'Enter') save(); }}>
		    <DialogTitle id="title">{props.title}</DialogTitle>
		    <DialogContent>
	        <TextField
				      sx={{width: '40ch'}}
	            id="line"
	            key="line"
	            name="line"
				      label={props.label} 
				      margin="dense"
	            variant="outlined"
	            onChange={handleChange}
	            autoFocus
	         />
     	 </DialogContent>
     	 <DialogActions sx={{pb: 3, pr: 3}}>
         	<Button color="primary" variant="contained" type="submit" onClick={save}>{i18n.t("bSave")}</Button>
         	<Button onClick={handleClose}>{i18n.t("bCancel")}</Button>
       </DialogActions>
     </Dialog>
   </>
  );
};

export default InputLine;