import React from 'react';
import { createRoot } from "react-dom/client";
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import App from './App.js';
import theme from './theme.js';
import Favicon from 'react-favicon';
import store from './store.js'
import { Provider } from 'react-redux'
import y42 from './components/y42.js';
import fav from './assets/img/y42_icon.ico';
import fav2 from './assets/img/sim4ny_icon.ico';

document.title = y42.getSiteName();

const container = document.querySelector('#root'); //document.getElementById("react");
const root = createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <CssBaseline />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <Favicon url={y42.getSiteName() === "Sim4ny" ? fav2 : fav }/>
      <App />
    </Provider>
  </ThemeProvider>
);