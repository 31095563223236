const ru = {
	__language__: "Русский",

	fID: "ID",
	fName: "Наименование", 
	fNewPassword: "Новый пароль",
	fRepeatNewPassword: "Повторите пароль",
	fSimulation: "Симуляция",
	fInitiator: "Инициатор",
	fNumberOfPlayers: "Число игроков",
	fNumberOfSteps: "Число раундов",
	fMinutesForOneStep: "Минут на раунд",
	fGame: "Игра",
	fRobot: "Это робот?",
	fUsers: "Пользователи",
	fPlayerProfile: "Профиль игрока",
	fScriptRobot: "Скрипт игрока",
	fLoginName: "Имя пользователя",
	fPassword: "Пароль",
	fRememberMe: "Запомнить меня",
	fDescription: "Описание",
	fAuthor: "Автор",
	fScript: "Скрипт",
	fFullName: "Полное имя",
	fEmail: "E-mail",
	fAccount: "Аккаунт",
	fRole: "Роль",
	fSelectLanguage: "Выберите язык",
	fMoveModeStrict: "Строгий режим хода",
	fStartTime: "Время старта",
	fVariant: "Вариант",
	fInvitationCode: "Пригласительный код",
	fPasswordRepeat: "Повторите пароль",
	fIsActive: "Показывать в сводке",
	fTerminationDate: "Срок действия аккаунта",
	fMaxPlayers: "Максимальное количество игроков",
	fLanguage: "Язык",
		
	mDashboard: "Сводка",
	mAccounts: "Аккаунты",
	mUsers: "Пользователи",
	mSimulations: "Симуляции",
	mGames: "Игры",
	mAsUser: "Войти как игрок",
	mMyGames: "Мои игры",
	mChangePassword: "Изменить пароль",
	mLogout: "Выйти",
	mChangeLanguage: "Сменить язык",
	mPreAlert: "Осталась одна минута до конца раунда. Не забудьте отправить ход",
	mSessionExpired: "Время сессии истекло. Введите логин и пароль заново",
	mIncorrectUserRegData: "Некорректные данные для регистрации. Заполните все поля корректно и попробуйте заново",
	mGameCloned: "Игра успешно клонирована. Перейти к ней: ",
	mAccountActive: "Активный",
	mAccountInactive: "Неактивный",

	tAdministrativePanel: "Панель администрирования",
	tChangePassword: "Изменить пароль",
	tDeleteRecord: "Удалить запись",
	tRunningGames: "Идущие сейчас игры",
	tHome: "Домашняя",
	tManagementPanel: "Панель управления",
	tAccounts: "Аккаунты",
	tGames: "Игры",
	tMyGames: "Мои игры",
	tCharts: "Графики",
	tParticipatingInGame: "Участие в игре",
	tRunningGame: "Управление игрой",
	tSignIn: "Войти",
	tSimulations: "Симуляции",
	tUsers: "Пользователи",
	tReport: "Отчет",
	tBasicDashboard: "Сводка",
	tOtherGames: "Другие игры",
	tPlayers: "Игроки",
	tPlayer: "Игрок",
	tHowToUseInvCode: "Отправьте этот код игрокам для регистрации",
	tSignUpComment: ", если у вас еще нет учетной записи",
	tSignUp: "Регистрация",
	tSignUpLinkDesc: "Поделитесь ссылкой или QR-кодом на страницу регистрации",
	tInvitationCode: "Пригласительный код",
	tSuccesfullyEndedRegistration: "Регистрация успешно завершена! Вы можете войти в приложение.",
	tCheckYourInbox: "Спасибо за регистрацию! Проверьте ваш почтовый ящик, который вы указали, и пройдите по ссылке в письме.",
	tRegistrationFailed: "Регистрация не подтверждена",
	tWelcomeDataInput: "Добро пожаловать! Позвольте нам узнать о вас:",
	tAccountDataInput: "Введите имя пользователя и пароль:",
	tAccountInfo: "Аккаунт",
	tDeficite: "Рост долга",
	tProficite: "Сокращение долга",

	bChangePassword: "Сохранить пароль",
	bCancel: "Отмена",
	bEditCode: "Правка",
	bCodeSave: "Сохранить",
	bNew: "Создать",
	bDeleteRecord: "Удалить",
	bPlayers: "Игроки",
	bRunGame: "Панель игры",
	bRunPlayerGame: "Играть в игру",
	bNextStep: "Следующий раунд",
	bDefinePlayers: "Задать игроков",
	bRestartGame: "Перезапустить",
	bStopGame: "Остановить",
	bStartGame: "Запустить",
	bUpdateToServer: "Отправить на сервер",
	bExportToFile: "Экспорт в файл",
	bLoadFromFile: "Загрузить из файла",
	bClearSituations: "Очистить ситуации",
	bClose: "Закрыть",
	bPrint: "Печатать",
	bSendMove: "Отправить ход",
	bSendMoveAgain: "Отправить ход повторно",
	bSignIn: "Войти",
	bSave: "Сохранить",
	bDelete: "Удалить",
	bToList: "К списку",
	bRefresh: "Обновить",
	bStepBack: "Шаг назад",
	bClearPassword: "Сбросить пароль",
	bPauseGame: "На паузу",
	bResumeGame: "Возобновить",
	bCopyPrevMove: "Взять предыдущий ход",
	bResetMove: "Сбросить к значениям по умолчанию",
	bFilter: "Применить",
	bGenerateCode: "Получить новый код",
	bClearCode: "Сбросить код",
	bConfirm: "OK",
	bSignUp: "Зарегистрируйтесь",
	bSignUpConfirm: "Зарегистрироваться",
	bRemindPassword: "Забыли пароль?",
	bBackToLogin: "Вернуться на страницу входа",
	bCloneGame: "Клонировать игру",
	bGeneratePlayers: "Генерировать игроков",
	bOpenTimer: "Открыть таймер",

	lDays: "дней",
	lOpen: "Открыть",
	lGames: "Игры",
	lGame: "Игра",
	lNowRunning: "Идущие игры",
	lTotalPlayers: "Всего игроков",
	lLoading: "Загрузка ...",
	lNoRunningGames: "Нет идущих игр",
	lAreYouSureToDelete: "Вы уверены, что хотите удалить запись?",
	lSavedSuccessfully: "Сохранено успешно",
	lDeletedSuccessfully: "Удалено успешно",
	lWhileGetting: "в процессе получения",
	lStep: "Раунд",
	lNextStepSuccessful: "Очередной раунд просчитан успешно",
	lStepBackSuccessful: "Возврат на один раунд прошел успешно",
	lSituationSentSuccessfully: "Ситуация успешно отправлена",
	lGameStoppedSuccessfully: "Игра остановлена успешно",
	lGameStartedSuccessfully: "Игра запущена успешно",
	lSituationsSavedSuccessfully : "Ситуации сохранены успешно",
	lSituationsLoadedSuccessfully: "Ситуации загружены успешно. Не забудьте отправить на сервер",
	lSituationsClearedSuccessfully: "Ситуации очищены успешно. Не забудьте отправить на сервер",
	lGameStatus: "Статус игры",
	lNoPlayers: "Нет игроков",
	lScore: "Счет",
	lRating: "Рейтинг",
	lGameEnded: "Завершена",
	lGameRunning: "Идет",
	lGameNotStarted: "Не запущена",
	lCurrentStep: "Текущий раунд",
	lAllStepsDone: "Завершены",
	lStepTimeLeft: "Время в раунде",
	lPlayerDone: "Ход отправлен",
	lPlayerThinking: "Думает",
	lSituation: "Ситуация",
	lResults: "Результаты",
	lSelectedPlayer: "Игрок",
	lSelectedStep: "Раунд",
	lDescription: "Описание",
	lMovePostedSuccessfully : "Ход отправлен успешно",
	lMove: "Ход",
	lSituationForStep: "Ситуация к раунду",
	lMoveForm: "Ход к раунду",
	lReportFor: "Отчет для",
	lReportForRound: "Отчет для раунда",
	lPasswordCleared: "Пароль сброшен успешно",
	lNewRecord: "[Новая запись]",
	lMinimumLimit: "не менее",
	lMaximumLimit: "не более",
	lMinimumLimitFrom : "в интервале от",
	lMinimumLimitTo : "до",
	lGamePausedSuccessfully: "Игра успешно поставлена на паузу", 
	lGameResumedSuccessfully: "Игра успешно возобновлена", 
	lGamePaused: "На паузе",
	lProfile: "профиль",
	lRemainingAmount: "Сумма к распределению",
	lGeneral: "Общий",
	lExactly: "точно",
	lSituationHint: "Разместите скрытые инсайты ситуаций в секции, разделенные ###",
	lInsight: "Внимание! Инсайт!",
	lNoOtherGames: "Нет игр",
	lGameVariant: "Вариант игры",
	lProfileLabel: "Профиль",
	lFilter: "Фильтр",
	lAdmin: "Владелец",
	lAccManager: "Администратор",
	lInvitationCode: "Пригласительный код для ",
	lTotal: "Всего",
	lStats: "Статистика работы с интерфейсом",
	lReadSituation: "Изучение ситуаций, сек.", 
	lReadResults: "Изучение результатов, сек.", 
	lChangeMove: "Количество изменений параметров хода", 
	lSendMove: "Количество отправок хода", 
	lSwitchTabs: "Количество переключений между вкладками", 
	lShowMoveEffects: "Количество показов влияния хода",
	lAboveAverage: "% выше среднего",
	lBelowAverage: "% ниже среднего",
	lExactlyAverage: "среднее",
	lAverage: "Среднее",
	lTeams: "Участники",
	lCommHi: "Высокая коммуникативность",
	lCommLo: "Низкая коммуникативность",
	lCommAv: "Умеренная коммуникативность",
	lAnalHi: "Глубокий анализ",
	lAnalLo: "Поверхностный анализ",
	lAnalAv: "Умеренный анализ",
	lExprHi: "Активное экспериментирование",
	lExprLo: "Слабое экспериментирование",
	lExprAv: "Умеренное экспериментирование",
	lGrades: "Профиль команды",
	lCommAx: "Коммуникативность",
	lAnalAx: "Анализ",
	lExprAx: "Экспериментирование",
	lAccountActive: "Аккаунт",
	lTerminationDate: "Срок действия",
	lMaxPlayers: "Игроков макс.",
	lInputPrefix: "Введите префикс",
	lPrefix: "Префикс",
	lStepBeingCalculated: "Обработка...",

	nAdmin : "Панель администратора",
	nUsers : "Пользователи",
	nNew : "Новая запись",
	nSimulations: "Симуляции",
	nGames: "Игры",
	nAccounts: "Аккаунты",
	nRun: "Панель игры",
	nHome: "Домашняя",
	nPlayers: "Игроки",
	nManage: "Панель управления",
	nMyGames: "Мои игры",

	errNoConnection: "Нет связи",
	errAccessDenied: "Доступ закрыт. Проверьте имя пользователя и пароль и попробуйте снова",
	errOperationUnavailable: "Операция недоступна",
	errOperationNotFound: "Операция отсутствует",
	errInternalServerError: "Внутренняя ошибка сервера",
	errUseLatingLettersInPassword:	"Используйте непустой пароль, только латинские буквы",
	errError: "Ошибка",
	errDoesntMatchFormat: "не соответствует формату",
	errExceedsRange: "превышает допустимый диапазон значений",
	errIsNegative: "должно быть больше или равно 0",
	errCannotLoadSituationsWhenGameStarted : "Нельзя загружать ситуацию, когда игра уже идет",
	errIncorrectFileFormat: "Некорректный формат файла",
	errCannotClearSituationsWhenGameStarted : "Нельзя очищать ситуацию, когда игра уже идет",
    errCannotBeGreaterThan: "не может превосходить",
	errCannotBeLessThan: "не может быть меньше",
	errThereAreErrorsOnSave: "Невозможно сохранить, ошибка в данных",
	errTogether: "суммарно",
	errEmptyPrefix: "Префикс не может быть пустым",

	msgGameStatusChanged: "ВНИМАНИЕ! Статус игры изменен!",
};

export default ru;