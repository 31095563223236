import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, TextField, Link, Paper, Box, Grid, Typography, Autocomplete, Snackbar, Alert } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';
import i18n from './i18n.js';
import y42 from './y42.js';
import theme from './../theme.js';
import bg1 from './../assets/img/bg1.jpg';
import bg2 from './../assets/img/bg2.jpg';
import bg3 from './../assets/img/bg3.jpg';
import bg4 from './../assets/img/bg4.jpg';
import bg5 from './../assets/img/bg5.jpg';
import bg6 from './../assets/img/bg6.jpg';
import bg7 from './../assets/img/bg7.jpg';
import bg8 from './../assets/img/bg8.jpg';
import bg9 from './../assets/img/bg9.jpg';
import bg10 from './../assets/img/bg10.jpg';
import bg11 from './../assets/img/bg11.jpg';
import bg12 from './../assets/img/bg12.jpg';

const i = Math.floor(Math.random() * 12);
var bgimg = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11, bg12];

function Copyright() {
  let host = window.location.host;

  return (
    <Box sx={{bottom: 20, left: 20, position: "absolute", zIndex: 0, textShadow: "#FFFFFF 1px 0 3px, #FFFFFF -1px 0 3px, #FFFFFF 0 1px 3px, #FFFFFF 0-1px 3px"}}>
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={y42.getSiteUrl()}>
        {y42.getSiteName()} Group
      </Link>{' '}
      {new Date().getFullYear() + " "}
      [{host}] // v. {y42.getVersion()}
    </Typography>
    </Box>
  );
}

const GridImage = styled(Grid)(
  ({ theme }) => ({
    backgroundImage: 'url(' + bgimg[i] + ')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }),
);

export default function SignInSide(props) {
  let navigate = useNavigate();
  const pageParams = useParams();

  const [state , setState] = useState({
        userName : "",
        password : ""
  })
  const langs = i18n.getLanguages();
  const startLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : window.navigator.language.substring(0, 2);
  const [lang, setLang] = useState(langs.find(item => item.key === startLang));
  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
  const [refreshed, setRefreshed] = useState(!(pageParams.lentgh > 0 && pageParams[0] === "refresh"));

  useEffect(() => {
    if (!refreshed) {
        setAlert({open: true, severity: "warning", message: i18n.t("mSessionExpired")});
        setRefreshed(true);
    }
  }, [refreshed]);

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
          ...prevState,
          [id] : value
     }))
  }

  const handleSubmitClick = (e) => {
      e.preventDefault();
      y42.login(state.userName, state.password)
         .then((res) => {
            if (res._status !== y42.ok) {
              let msg = y42.getMessage(res._status, res.message);
              setAlert({open: true, severity: "error", message: msg});
            }
            else {
                let url = sessionStorage.getItem('returnURL');
                if (url && url !== "") {
                  navigate(url);
                  return;
                }
                let user = y42.getUser();
                if (user.role.name === "ROLE_ADMIN")
                  navigate("/admin");
                else if (user.role.name === "ROLE_ACCMANAGER")
                  navigate("/manage");
                else if (user.role.name === "ROLE_USER")
                  navigate("/home");
            }
        });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({open: false, severity: "info", message: ''});
  };

  const form = (
          <Box sx={{width: "50vh", maxWidth: "100%"}}>
          <form noValidate>
            <Autocomplete
              id="languageSelect" 
              key="languageSelect"
              name="languageSelect"
              fullWidth
              label={i18n.t("fSelectLanguage")} 
              disableClearable
              options={langs}
              value={lang}
              onChange={(ev, value) => {
                            i18n.config(value.key);
                            setLang(value);
                          }}
              getOptionLabel={
                            (option) => {
                              return (option && option.value)?option.value:""
                              }
                          }
              isOptionEqualToValue={
                (option, value) => option.key === value.key
              }
              renderInput={(params) => (
                              <TextField 
                                  {...params}
                                  margin="normal" 
                                  variant="outlined"/>
                            )}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="userName"
              label={i18n.t("fLoginName")}
              name="userName"
              autoComplete="login"
              value={state.userName}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t("fPassword")}
              type="password"
              id="password"
              autoComplete="current-password"
              value={state.password}
              onChange={handleChange}
            />
            {/*
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={i18n.t("fRememberMe")}
            />*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ margin: theme.spacing(3, 0, 2) }}
              onClick={handleSubmitClick}
            >
              {i18n.t("bSignIn")}
            </Button>
            <Box sx={{pt: 2, justifyContent : "center", display: "flex"}}>
              <Typography>
                <Link href="signup">{i18n.t("bSignUp")}</Link>
                {i18n.t("tSignUpComment")}
              </Typography>
            </Box>{/*
            <Box sx={{pt: 2, justifyContent : "center", display: "flex"}}>
              <Link href="/">
                {i18n.t("bRemindPassword")}
              </Link>
            </Box>*/}
          </form>
          </Box>
    );

  return (
    <>
    <Copyright/>
    <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert onClose={handleClose} severity={alert.severity}>
          {alert.message}
        </Alert>
    </Snackbar>

    <Grid container component="main" sx={{height: '100vh'}}>
      <CssBaseline />
  
      <GridImage item xs={false} sm={4} md={7}/>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}  sx={{zIndex: 1000}}>
        <Paper sx={{padding: 4, paddingTop: 16, display: 'flex', flexDirection: 'column', alignItems: 'center', height: "100%" }}>
          <Avatar sx={{margin: 1, backgroundColor: theme.palette.secondary.main}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {i18n.t("tSignIn")}
          </Typography>
          {form}
        </Paper>
      </Grid>
    </Grid>
    </>
  );
}